/* ----------------------------------------------------------------------------
 * Lighty framework Frontend Starter - Main
 * ----------------------------------------------------------------------------
 */

// import variables & mixins
@import '_configuration';
@import '_helpers/_helpers';

//import preload.css
@import '_preload';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

* {
    font-family: 'Open Sans', sans-serif;
}

.cleaner {
    &.h20 {
        height: 20px;
    }
    &.h60 {
        height: 60px;
    }
}

//apply sytyle

//colors
$color-1        :#662d91;
$color-2        :#662d91;
$color-3        :#1aa89e;
$color-4        :#662d91;
$background     :#ededed;
$grey           :#575757;

//page style
@keyframes jump {
    0% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
    }
    50% {
        box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
        transform: translatey(-15px);
    }
    100% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
    }
}

body {
    background-color: $background;
}
main {
    max-width: 1460px;
    margin: 0 auto;
    min-height: 95vh;
}
section{
    position: relative;
    background-color: #f2f2f2;
    overflow: hidden;
    padding-bottom: 30px;
    .gridContainer {
        position: relative;
        text-align: center;
        label {
            font-style: normal;
            font-weight: 400;
            &.error {
                font-size: 12px;
                padding: 10px 0;
                display: block;
            }
        }
        .badge {
            display: inline-block;
            width: 60px;
            height: 60px;
            color: #ffffff;
            font-size: 24px;
            line-height: 60px;
            font-weight: 700;
            border-radius: 50%;
            background-color: #d8d8d8;
            margin-bottom: 20px;
            &.active {
                background-color: $color-4;
                transform: translateY(0px);
                animation: jump 3.5s ease-in-out forwards;
                animation-iteration-count: 2;
            }
        }
        h5 {
            color: $grey;
            padding: 30px 0 45px 0;
            margin: 0;
            &::after {
                width: 300px;
                background-color: $color-4;
                content: ' ';
                height: 2px;
                position: absolute;
                top: 70px;
                left: 50%;
                margin-left: -150px;
                @include max-width (362px) {
                    width: 200px;
                    margin-left: -32%;
                    top: 90px;
                }
            }
            &::before {
                top: 70px;
                background-color: $color-4;
                content: ' ';
                width: 2px;
                height: 40px;
                position: absolute;
                left: 50%;

                @include max-width(799px) {
                    display: none;
                }
            }
        }
        .not-good {
            text-align: left;
            border: 3px dashed $color-4;
            padding: 15px;
            line-height: 1.6em;
            @include border-radius (6px);
            background-color: lighten($color-4, 60%);
            /* background-color: #ffffff; */
            /* a {
                border: 1px solid $primary-color;
                padding: 5px;
                @include border-radius (5px);
                color: $primary-color;
            } */
            /* span {
                margin-top: 20px;
                font-size: 2rem;
                color: $color-4;
                font-weight: 700;
            } */
            .phoneFlex {
                display: flex;
                align-items: center;
                min-width: 310px;
                font-weight: 700;
            }
            .phoneNumber {
                /* margin-bottom: 20px; */
                display: flex;
                align-items: center;
            }
            .phoneNumber a {
                color: $color-4;
                text-decoration: none;
                margin-right: 5px;
                span {
                    float: right !important;
                }
            }
            .button-show {
                padding: 6px 14px;
                border: 1px solid $color-4;
                color: $color-4;
                @include border-radius(6px);
                text-decoration: none;
                margin-left: 5px;
                font-size: 16px;
            }
            .hidden-number {
                margin-left: 5px;
            }
        }
        .col-1 {
            background-color: transparent;
        }
    }
    &.stripe {
        height: 30px;
        background: #662d91;
        background: -moz-linear-gradient(left,  #662d91 0%, #1aa89e 100%);
        background: -webkit-linear-gradient(left,  #662d91 0%,#1aa89e 100%);
        background: linear-gradient(to right,  #662d91 0%,#1aa89e 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#662d91', endColorstr='#1aa89e',GradientType=1 );
    }
    &.hidden {
        height: 1px;
        overflow: hidden;
    }
    &.visible {
        height: auto;
    }
    &.step {
        &:before {
            content: ' ';
            width: 2px;
            height: 100%;
            position: absolute;
            top: 70px;
            left: 50%;
            background-color: #d8d8d8;

            @include max-width(799px) {
                display: none;
            }
        }
    }
    &.active {
        .badge {
            background-color: $color-4;
        }
    }
    &.ready {
        .badge {
            background: url(../image/icon-tick.png) center center no-repeat lighten($color-4, 15%);
            text-indent: -99999px;
        }
        &::before {
            background-color: $color-4;
        }

    }
    &.title {
        background-color: #ffffff;
        padding: 20px;
        .gridContainer {
            .col-1 {
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    figure {
                        width: 100%;
                        height: auto;
                        padding: 0;
                        margin: 0;
                        max-width: 140px;
                    }
                    .h5 {
                        padding-left: 15px;
                        color: $color-1;
                        margin-bottom: 10px;
                    }
                    @include max-width ($medium) {
                        flex-direction: column;
                        h6 {
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
                h2 {
                    margin: 0;
                    padding-bottom: 0;
                }
                .subtitle {
                    color: $color-4;
                    text-transform: uppercase;
                    font-weight: 300;
                }
                .notification {
                    text-align: center;
                    background-color: $color_success;
                    p {
                        color: #ffffff;
                    }
                }
            }
        }
    }
    &.service {
        .col-1 {
            display: flex;
            justify-content: center;
            flex-direction: column;
            label {
                text-align: center;
                font-size: 1em;
                margin-bottom: 10px;
            }
            .custom-form {
                margin: 0 auto;
                display: flex;
                justify-content: center;
                width: 100%;
                padding-left: 0;
                select {
                    &.custom {
                        display: flex;
                        width: 100%;
                        max-width: 600px;
                        &::after {
                            position: absolute;
                            content: ' ';
                            display: block;
                            right: 20px;
                            border: solid black;
                            border-width: 0 3px 3px 0;
                            display: inline-block;
                            padding: 3px;
                            transform: rotate(45deg);
                            -webkit-transform: rotate(45deg);
                        }
                    }
                }
            }
        }
    }
    &.booking {
        position: relative;
        height: 1px;
        overflow: hidden;
        &.active, &.ready {
            height: auto;
        }
        .col-2 {
            label {
                font-size: 1em;
                &.calendar {
                    width: 100%;
                    .datepicker {
                        margin: 10px 0 20px 0;
                        font-size: 22px;
                    }
                    .hasDatepicker {
                        width: 100%;
                        .ui-datepicker {
                            width: 90%;
                            @include max-width(680px) {
                                width: 100%;
                            }
                        }
                    }
                }
                &.time {
                    width: 90%;
                    float: right;
                    .available-hours {
                        margin: 10px 0 20px 0;
                        .available-hour {
                            line-height: 40px;
                            height: 40px;
                            padding: 10px 12px;
                            color: #ffffff;
                            background-color: $color-4;
                            font-size: 16px;
                            @include border-radius(5px);
                            &:hover {
                                background: linear-gradient(to right, $color-4 1%, $color-3 100%);
                            }
                            &.selected {
                                background-color: darken($color-4, 20%);
                                position: relative;
                                @include min-width(470px) {
                                    &::after {
                                        content: '(választott időpont)';
                                        width: auto;
                                        min-width: 80px;
                                        position: absolute;
                                        display: block;
                                        left: 0;
                                        bottom: -30px;
                                        color: $color-4;
                                        float: left;
                                        margin-left: 0px;
                                        font-size: 12px;
                                        line-height: 14px;
                                        font-weight: 500;
                                    }
                                }
                            }
                        }
                        .mobile-only {
                            margin-top: 40px;
                            a {
                                color: $color-1;
                            }
                        }
                    }
                    @include max-width(680px) {
                        width: 100%;
                        margin-top: 20px;
                    }
                }
            }
            .desktop-only {
                width: 90%;
            }
            @include max-width(680px) {
                width: 100%;
                .desktop-only {
                    width: 100%;
                }
            }
        }
        .badge {
            margin-left: 0;
            top: 100px;
            @media screen and (min-width: 681px) {
                position: absolute;
                margin-left: -30px;
            }
        }
    }
    &.contact {
        height: 1px;
        overflow: hidden;
        &.active, &.ready {
            height: auto;
        }
        .gridContainer {
            form {
                .col-1 {
                    display: flex;
                    flex-direction: column;
                    label {
                    font-size: 12px;
                        &.description {
                            .checkContainer {
                                .chkForm {
                                    label {
                                        margin-right: 0;
                                    }
                                }
                                .chkText {
                                    display: inline-block;
                                    line-height: 25px;
                                    @include max-width (446px) {
                                        padding-top: 20px;
                                    }
                                    @media screen and  (min-width:833px) and (max-width:1037px) {
                                        padding-top: 20px;
                                    }
                                }
                            }
                            input {
                                min-width: 20px;
                                max-width: 20px;
                                margin: 0 10px 0 0;
                                border: 1px solid $color-2;
                            }
                        }
                        &.description-big {
                            font-size: 16px;
                            margin: 20px auto;
                        }
                        @include min-width(693px) {
                            &.terms {
                                line-height: 5;
                            }
                        }
                    }
                    button {
                        min-width: 200px;
                        width: 50%;
                        margin-top: 20px;
                        font-size: 1.2em;
                        font-weight: 700;
                        background-color: $color-4;
                        align-self: center;
                        justify-self: center;
                    }
                }
            }
        }
    }
}

// Include all styles (also index/home styles)
section {
    &.title {
        a {
            h6 {
                font-weight: 400;
            }
        }
        h2 {
            font-weight: 300;
            padding: 15px;
            @include max-width (680px) {
                font-size: 1.3em;
            }
        }
    }
    &.appointment {
        .steps {
            .gridContainer {
                h5 {
                    font-size: 20px;
                    font-weight: 400 !important;
                    &:after {
                        @include max-width (362px) {
                            width: 200px;
                            margin-left: -32%;
                            top: 90px;
                        }
                    }
                }
                label {
                    strong {
                        font-size: 16px;
                        color: $grey;
                    }
                }
                .col-1 {
                    margin-top: 60px;
                }
            }
            &.service {
                .first {
                    h5 {
                        font-size: 20px;
                    }
                }
                .badge {
                    position: absolute;
                    margin-left: -30px;
                }
                .col-2 {
                    &.doctor {
                        label, .custom-form {
                            padding-right: 10%;
                            margin-right: 0;
                            select {
                                margin-top: 10px;
                            }
                        }
                        .custom-form {
                            padding-left: 0;
                        }
                    }
                    &.service {
                        label, .custom-form {
                            padding-left: 10%;
                            margin-right: 0;
                            margin-left: 10px;
                            select {
                                margin-top: 10px;
                            }
                        }
                    }
                }
                @include max-width (680px) {
                    .badge {
                        position: static;
                        margin-left: 0;
                    }
                    .col-2 {
                        width: 100%;
                        &.doctor {
                            label, .custom-form {
                                padding-right: 0;
                                margin-right: 0;
                            }
                        }
                        &.service {
                            label, .custom-form {
                                padding-left: 0;
                                margin-right: 0;
                                margin-left: 0px;
                            }
                        }
                    }
                }
            }
            &.booking {
                /* background-color: #f2f2f2; */
                .gridContainer {
                    .col-2 {
                        label {
                            .datepicker {
                                width: 100%;
                                color: #ffffff;
                                padding: 0;
                                .datepicker-inline {
                                    width: 100%;
                                    .datepicker-days, .datepicker-months, .datepicker-years {
                                        width: 100%;
                                        table {
                                            width: 90%;
                                            background-color: $color-4;
                                            @include border-radius(8px);
                                            @include max-width (680px) {
                                                width: 100%;
                                            }
                                            thead {
                                                tr {
                                                    .prev {
                                                        visibility: visible !important;
                                                    }
                                                    .datepicker-switch {
                                                        font-size: 20px;
                                                        padding: 20px 0;
                                                    }
                                                    &:first-child {
                                                        th {
                                                            &:hover {
                                                                background: lighten($primary-color, 8%);
                                                            }
                                                        }
                                                    }
                                                    .dow {
                                                        padding-top: 20px;
                                                        font-weight: 700;
                                                        font-size: 16px;
                                                        color: $color-4;
                                                        background-color: #fafafa;
                                                        @include border-radius(0);
                                                        border: 1px solid transparent;
                                                    }
                                                }
                                                @include max-width (680px) {
                                                    width: 100%;
                                                }
                                            }
                                            tbody {
                                                background-color: #fafafa;
                                                tr {
                                                    td {
                                                        @include border-radius(0);
                                                        font-size: 16px;
                                                        padding: 10px 0;
                                                    }

                                                }
                                            }
                                            .day {
                                                background: none;
                                                border: 1px solid transparent;
                                                &:hover {
                                                    background-color: lighten($color-4, 35%);
                                                    outline: 1px solid $color-4;
                                                    outline-offset: -1px;
                                                }
                                                &.disabled {
                                                    color: #cccccc;
                                                    border: 1px solid transparent;
                                                    &:hover {
                                                        background-color: #fafafa;
                                                        border: 1px solid #cccccc;
                                                        outline: 1px solid #cccccc;
                                                        outline-offset: -1px;
                                                    }
                                                }
                                                &.old, &.new {
                                                    color: transparent;
                                                    border: 1px solid transparent;
                                                    &:hover {
                                                        border: none;
                                                        background: none;
                                                        border: 1px solid transparent;
                                                        outline: 0;
                                                        outline-offset: 0;
                                                    }
                                                }
                                                &.active {
                                                    font-weight: 700;
                                                    color: #ffffff;
                                                    background-color: lighten($color-4, 15%);
                                                    background-image: none;
                                                    border: 1px solid transparent;
                                                }
                                            }
                                            .today {
                                                font-weight: 700;
                                                color: #ffffff !important;
                                                background-color: lighten($color-4, 45%);
                                                background-image: none;
                                                border: 1px solid transparent;
                                                &:hover {
                                                    background-color: lighten($color-4, 35%);
                                                    color: #ffffff !important;
                                                    font-weight: 700;
                                                    border: 1px solid transparent;
                                                }
                                                &.active {
                                                    font-weight: 700;
                                                    color: #ffffff;
                                                    background-color: $color-4;
                                                    background-image: none;
                                                    border: 1px solid transparent;
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                            .available-hours {
                                div {
                                    display: flex;
                                    flex-wrap: wrap;
                                    button {
                                        &.availableTime {
                                            font-size: 16px;
                                            padding: 0 12px;
                                            margin-bottom: 27px;
                                            margin-right: 10px;
                                            background: #dddddd;
                                            color: $grey;
                                            font-weight: 400;
                                        }
                                        &:hover {
                                            border: 1px solid $color-4;
                                        }
                                        &.selected {
                                            background: lighten($color-4, 15%);
                                            color: #ffffff;
                                            font-weight: 700;
                                            display: block;
                                            float: left;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.contact {
                .gridContainer {
                    .col-1 {
                        display: flex;
                        flex-direction: column;
                        margin-top: 0;
                        .checkContainer {
                            margin-bottom: 0px;
                            .chkForm {
                                /* position: absolute;
                                left: 10px; */
                                .custom-form {
                                    margin-top: -5px;
                                    margin-right: 5px !important;
                                    display: block;
                                    position: relative;
                                    margin-bottom: 12px;
                                    cursor: pointer;
                                    font-size: 16px;
                                    line-height: 25px;
                                    padding-left: 33px;
                                    -webkit-user-select: none;
                                    #agree {
                                        height: auto;
                                    }
                                    .custom-form input[type=checkbox] {
                                        position: absolute;
                                    }
                                    .custom-form input[type=checkbox]:checked~.check,
                                    .custom-form input[type=checkbox]:checked~.check:hover,
                                    .custom-form input[type=radio]:checked~.check,
                                    .custom-form input[type=radio]:checked~.check:hover {
                                        background-color: $secondary-color;
                                        border: 1px solid $secondary-color;
                                    }
                                    span {
                                        margin-top: 20px;
                                    }
                                }
                            }
                            .chkText {
                                font-size: 12px;
                                color: $grey;
                                padding-top: 0;
                                text-align: left;
                                padding-left: 40px;
                                text-align: left;
                                display: flex;
                                a {
                                    font-weight: 700;
                                    color: $color-4;
                                    text-decoration: underline;
                                }
                                &.terms {
                                    display: block;
                                }
                            }
                        }
                        .cleaner {
                            height: 40px;
                        }
                        label {
                            display: flex;
                            width: 100%;
                            padding-bottom: 8px;
                            input {
                                vertical-align: middle;
                                display: inline;
                                width: 30px;
                                height: 20px;
                                margin-bottom: 0;
                            }
                            &.description-big {
                                font-size: 17px;
                                color: $grey;
                                display: block;
                            }
                        }
                        .btn-primary {
                            width: 50%;
                            max-width: 500px;
                            min-width: 300px;
                            align-self: center;
                            margin-top: 40px;
                            font-weight: 700;
                            font-size: 16px;
                            background-color: $color-1;
                            &:hover {
                                background-color: lighten($color-1, 10%);
                            }
                        }
                    }
                }
            }
        }
        &.resign {
            .gridContainer {
                .col-1 {
                    display: flex;
                    justify-content: center;
                    form {
                        h4 {
                            color: $primary-color;
                            text-align: center;
                            margin-bottom: 40px;
                            font-family: 'Montserrat', sans-serif;
                        }
                        textarea {
                            margin-bottom: 40px;
                        }
                        .send {
                            text-align: center;
                            .btn {
                                min-width: 300px;
                                color: #ffffff;
                                background-color: $color-1;
                                font-weight: 700;
                                &:hover {
                                    background-color: lighten($color-1, 10%);
                                }
                            }
                        }
                        p {
                            span {
                                color: $color-3;
                            }
                        }
                    }
                }
            }
        }
    }
    &.success {
        .gridContainer {
            .col-1 {
                text-align: center;
                h3 {
                    color: $primary-color;
                }
            }
        }
    }
}

footer {
    background-color: #ededed;
    padding: 20px 0;
    .gridContainer {
        .col-1 {
            text-align: center;
            p {
                &.small {
                    color: $color-1;
                    font-size: 13px;
                    margin: 0 auto;
                    a {
                        font-weight: 700 !important;
                        color: $color-1;
                        text-decoration: none !important;
                        &:hover {
                            color: lighten($color-1, 10%);
                        }
                    }
                }
            }
            .copy {
                float: none;
                color: $color-1;
                a {
                    font-weight: 700 !important;
                    color: $color-1;
                    text-decoration: none !important;
                    &:hover {
                        color: lighten($color-1, 10%);
                    }
                }
            }
            .bf-logo {
                width: 120px !important;
                max-width: 120px;
                margin: 0 auto;
                display: block;
                margin-top: 20px;
            }
        }
    }
}

.alert {
    &.resign {
        font-weight: 700;
        text-align: center;
    }
}
